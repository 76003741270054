import { MonthPickerModel, TimeInfo, TimeViewFormat, UTCTime } from "@/models/TimeModel";
import moment from 'moment';

function getInfoFromUTCTime(utcTime: UTCTime | null | undefined): TimeInfo {
  if (utcTime) {
    const utcTimeDate = new Date(utcTime)
    const day: number = utcTimeDate.getDate()
    const month: number = utcTimeDate.getMonth()
    const year: number = utcTimeDate.getFullYear()
    const hour: any = numberView(utcTimeDate.getHours())
    const minutes: any = numberView(utcTimeDate.getMinutes())
    return {
      year,
      month,
      day,
      hour,
      minutes
    }
  } else {
    throw Error('no UTC time')
  }
}

function createDateForViewFromUTCTime(date = new Date()): string {
  return moment(date).format('DD-MM-YYYY')
}

function numberView(num: number): string {
  return `${Number(num) < 10 ? `0${num}` : num}`
}

function convertDate(editDate: any) {
  const date = new Date(editDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`
}

export default {
  getInfoFromUTCTime,
  createDateForViewFromUTCTime,
  numberView,
  convertDate
}

